.settingsContainer {
  background: #fafafa;
}
.personalInformationContainer {
  padding: 40px 20px;
}
.personalInfoCard {
  background: white;
  border-radius: 16px;
  padding: 10px;
  margin-top: 24px;
}
.personalProfileAvatar {
  border: 0.5px #f0f1f2 solid;
  background: white;
  border-radius: 100px;
  height: 48px;
  width: 48px;
}
.personalProfileAvatar img {
  width: 100%;
  height: 100%;
}
.personalInfoCardHeader {
  padding-bottom: 26px;
  border-bottom: 0.25px #e6e7e9 solid;
  margin-bottom: 24px;
}

@media screen and (min-width: 1200px) {
  .personalInformationContainer {
    padding: 40px 80px;
  }
  .personalInfoCard {
    padding: 20px;
  }
}

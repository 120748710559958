.filterDropContainer {
  gap: 8px;
  /* width: 149px; */
  height: 40px;
  border-radius: 16px;
  margin: 0 10px;
  transition: 0.3s ease-in-out all;
  padding: 0 10px;
  background: #f9f9fb;
}
.filterListContainer {
  padding: 10px;
  /* gap: 8px; */
  /* width: 149px;
  height: 154px; */
  width: 149px;
  max-height: 300px;
  overflow: scroll;
  background: #fafafa;
  box-shadow: 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  border-radius: 0px 0px 16px 16px;
  position: absolute;
  z-index: 10;
}
.filterListItem {
  padding: 4px 12px 4px 6px;
  background: #ffffff;
  border-radius: 8px;
  margin-bottom: 8px;
  color: #696a6b;
  cursor: pointer;
}
.filterListItem:hover {
  background-color: #ffedd6;
  color: #ffb049;
}
.foodCardContainer {
  box-shadow: 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  filter: drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24));
  border-radius: 16px;
  max-height: 420px;
  height: 420px;
  width: 100%;
  min-width: 300px;
  max-width: 300px;
  background: #fafafa;
  transition: all 0.3s ease-in-out;
  object-fit: cover;
}
.foodCardImage {
  height: 208px;
  border-radius: 16px 16px 0 0;
  position: relative;
}

.foodCardOverlay {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  padding: 16px;
  border-radius: 16px 16px 0 0;
}
.foodCardOverlay span:first-child {
  width: 40px;
  height: 40px;
  background: #f9f9fb;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.foodCardOptions {
  position: absolute;
  width: 160px;
  /* height: 158px; d          */
  right: 16px;
  top: 16px;
  background: #ffffff;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  transition: 0.3s ease-in-out all;
  z-index: 100;
}
.foodCardOptionItem {
  padding: 4px 8px 4px 6px;
  margin-bottom: 8px;
  height: 28px;
  background: #fafafa;
  border-radius: 8px;
}
.foodCardDetails {
  padding: 12px 16px 16px;
  /* background: #fafafa; */
}
.unlistedOverLay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(1, 1, 1, 0.8);
  /* color: #fff; */
  border-radius: 16px 16px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
}
.unlistedOverLay span {
  width: 40px;
  height: 40px;
  background: #f9f9fb;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 16px;
  right: 16px;
}

.borderBtm {
  border-bottom: 0.5px solid #d6d6d8;
  padding-bottom: 8px;
  margin-bottom: 8px;
}

.foodGrid {
  padding: 40px 20px 100px 20px;
  justify-content: center;
  align-items: center;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 40px 20px;
  transition: all 0.3s ease-in-out;
}

.createWithAIModal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: flex-end !important;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  padding-top: 20px;
}

.createWithAIModal.open {
  opacity: 1;
  visibility: visible;
  align-items: center; /* Center the modal when open */
}

/* Modal content */
.createWithAIModalContent {
  background: white;
  width: 100%;
  /* max-width: 100%; */
  padding: 40px 20px;
  border-radius: 16px 16px 0 0;
  transform: translateY(100%); /* Start from the bottom */
  transition: transform 0.3s ease, opacity 0.3s ease;
  opacity: 0;
  max-height: 700px;
  overflow: scroll;
}

.createWithAIModal.open .createWithAIModalContent {
  transform: translateY(0); /* Move to the center */
  opacity: 1;
}

.aiLoader {
  width: 30px; /* Reduced size */
  aspect-ratio: 1;
  display: grid;
  border: 3px solid #0000; /* Reduced border size */
  border-radius: 50%;
  border-right-color: #ffa024;
  animation: l15 1s infinite linear;
}

.aiLoader::before,
.aiLoader::after {
  content: "";
  grid-area: 1/1;
  margin: 1.5px; /* Adjusted margin */
  border: inherit;
  border-radius: 50%;
  animation: l15 2s infinite;
}

.aiLoader::after {
  margin: 6px; /* Adjusted margin */
  animation-duration: 3s;
}

@keyframes l15 {
  100% {
    transform: rotate(1turn);
  }
}

@media screen and (min-width: 768px) {
  .createWithAIModal {
    align-items: center !important;
  }
  .createWithAIModalContent {
    max-width: 500px;
    transform: translateY(0);
    opacity: 1;
    border-radius: 16px;
    margin: auto;
    max-height: 500px;
  }
}

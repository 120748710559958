.overViewContainer {
  padding: 40px 20px;
  background-color: #fafafa;
}

.recentTransactionsContainer {
  padding-bottom: 24px;
  max-height: 400px;
  background: #ffffff;
  border-radius: 16px;
  overflow: scroll;
}
.recentTransactionsHeader {
  padding: 16px;
  border-bottom: 1px solid #edf2f7;
  position: sticky;
  top: 0;
  background-color: #ffffff;
}

.statsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 16px;
  width: 100%;
}

/* Basic styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
}

.modal-overlay.open {
  opacity: 1;
  pointer-events: all;
}

/* Slider modal container */
.slider-modal {
  position: fixed;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.4s ease;
  overflow-y: auto;
  padding: 20px;
  border-radius: 10px 10px 0 0;
  z-index: 1;
}

/* Button styles */
.open-modal-button,
.close-modal-button {
  cursor: pointer;
  padding: 10px 20px;
  margin: 10px;
  color: white;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s;
  position: absolute;
  right: 0px;
  top: 80px;
}

/* Desktop: slide from the right */
@media (min-width: 768px) {
  .slider-modal {
    top: 0;
    right: 0;
    transform: translateX(100%);
    padding-top: 160px;
    height: 100%;
    max-width: 250px;
    width: 100%;
  }

  .slider-modal.open {
    transform: translateX(0);
  }
}

/* Mobile: slide from the bottom */
@media (max-width: 767px) {
  .slider-modal {
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateY(100%);
    height: auto;
    padding-top: 60px;
  }
  .open-modal-button,
  .close-modal-button {
    top: 0px;
  }

  .slider-modal.open {
    transform: translateY(0);
  }
}

@media screen and (min-width: 1200px) {
  /* .statsGrid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
  } */
  .overViewContainer {
    padding: 40px 80px 160px 80px;
  }
}

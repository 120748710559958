.flexRow {
  display: flex;
  flex-direction: row;
}
.flexColumn {
  display: flex;
  flex-direction: column;
}

.flexWrap {
  flex-wrap: wrap;
}

.justifyBetween {
  justify-content: space-between;
}

.justifyCenter {
  justify-content: center;
}
.justifySelfEnd {
  justify-self: flex-end;
}
.justifySelfCenter {
  justify-self: center;
  /* border: 1px solid red; */
}
.justifyEnd {
  justify-content: flex-end;
}

.alignCenter {
  align-items: center;
}
.alignBetween {
  align-items: stretch;
}

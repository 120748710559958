.searchContainer {
  padding: 20px 0;
}
.searchContentContainer {
  padding: 20px;
}
.searchContainer .searchInputContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1.5px solid #e6e7e9;
  margin-top: 20px;
  /* padding-bottom: 12px; */
}
.searchContainer .searchInputContainer input {
  border: none;
  height: 100%;
  width: 100%;
  padding: 10px;
  background-color: inherit;
  outline: none;
  font-size: 20px;
  color: #919294;
}
.searchContainer .searchInputContainer input::placeholder {
  color: #e6e7e9;
  font-size: 20px;
}
.searchContainer .predefinedWordContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 24px 16px;
  margin-top: 20px;
  padding: 0 20px;
}
.searchContainer .predefinedWordContainer .predefinedWordBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 12px 4px 8px;
  gap: 4px;
  height: 32px;
  background: #f0f1f2;
  border-radius: 16px;
  border: none;
}

.searchGrid {
  padding: 0 0 100px 0;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 16px;
}
.emptySearchContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.searchTabContainer {
  display: flex;
  align-items: center;
  gap: 40px;
  justify-content: center;
}
.searchTabContainer button {
  background-color: inherit;
  border: none;
  transition: 0.3s ease-in-out all;
}
.searchTabContainer .activeTabButton {
  border-bottom: 2px solid #ffa024;
}
.brdBottomCurved {
  box-shadow: 0px 0px 0px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  border-radius: 0px 0px 24px 24px;
}
#searchHeaderContainer {
  padding: 0 20px;
}
.searchFilterContainer {
  padding: 40px 0 20px 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

@media screen and (min-width: 1200px) {
  .searchContainer {
    padding: 64px 0;
  }
  .searchContainer .searchInputContainer input {
    font-size: 30px;
  }
  .searchContainer .searchInputContainer input::placeholder {
    font-size: 30px;
  }
  .searchContentContainer {
    padding: 0 84px;
  }
  .searchContainer .predefinedWordContainer {
    padding: 0 100px;
    margin-top: 68px;
  }
  .searchGrid {
    grid-template-columns: repeat(3, 1fr);
    gap: 40px 16px;
  }
  #searchHeaderContainer {
    padding: 0 84px;
  }
}

.userManagementContainer {
  padding: 32px 20px;
}
.userGridCardContainer {
  width: 100%;
  max-height: 312px;
  box-shadow: 0px 2.19952px 5.86538px -0.733173px rgba(50, 50, 71, 0.05);
  filter: drop-shadow(0px 0px 0.733173px rgba(12, 26, 75, 0.24));
  border-radius: 12px;
}
.userGridCardImage {
  width: 100%;
  height: 200px;
  background-color: #d9d9d9;
  border-radius: 12px 12px 0px 0px;
  position: relative;
}
.userGridCardStatus {
  position: absolute;
  right: 16px;
  bottom: 16px;
  background-color: green;
  padding: 4px 8px;
  border-radius: 10px;
  color: white;
}

.userGridCardContent {
  background: #fafafa;
  border-radius: 0px 0px 16px 16px;
  padding: 12px;
}
.userGridCardInfo {
  background-color: #f0f1f2;
  padding: 4px 10px;
  border-radius: 8px;
  margin-top: 12px;
}
.userManagementGridView {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 40px 20px;
  padding: 40px 0;
}

.userModalContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(61, 56, 51, 0.5);
  z-index: 30;
  height: 100vh;
  display: flex;
  justify-content: flex-end;
}

.userModalContent {
  max-width: 639px;
  width: 639px;
  background-color: white;
  height: 100vh;
  padding: 100px 40px 0 40px;
}
.userProfileAvatar {
  height: 50px;
  width: 50px;
  border-radius: 100px;
  /* border: 1px solid red; */
  margin-right: 12px;
}
.actionButton {
  width: 40px;
  height: 40px;
  border: 1px solid #b1b2b4;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.userProfileStatus {
  width: 60px;
  height: 24px;
  background: #66bb6a;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fafafa;
  margin-left: 12px;
}

.userTabsContainer {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 24px;
}
.activeProfileTab {
  color: #ffb049;
  padding-bottom: 8px;
  box-shadow: inset 0px -2px 0px #ffa024;
  transition: 0.3s ease-in-out all;
  /* border-bottom: ; */
}

.inactiveProfileTab {
  box-shadow: none;
  color: #b1b2b4;
  padding-bottom: 0;
}

.userLargeCardContainer {
  background: #ffffff;
  border: 1px dashed #ffffff;
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  border-radius: 8px;
  padding: 20px;
}
.userLargeCardHeader {
  border-bottom: 0.5px solid #e6e7e9;
  margin-bottom: 24px;
  padding-bottom: 16px;
  display: flex;
  align-items: center;
}

.orderHistoryCardContainer {
  padding: 16px 16px 24px;
  background: #f9f9fb;
  border: 1px solid #696a6b;
  border-radius: 16px;
  margin-bottom: 24px;
}
.orderHistoryCardStatusContainer {
  padding: 2px 8px;
  background: #e1e8ff;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* color: "#fff"; */
}

@media screen and (min-width: 768px) {
  .userManagementContainer {
    padding: 32px 80px;
  }
}

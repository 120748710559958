.navContainer {
  background: #ffffff;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
}
.navContainer .logoContainer {
  display: none;
}
.navContainer .logoContainer img {
  width: 48px;
  height: 48px;
  object-fit: contain;
}
.navContainer .linksContainer {
  width: 100%;
}
.navContainer ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border: 1px solid red; */
}
.navContainer ul li {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f9f9fb;
  /* margin-right: 42px; */
}
.navContainer ul li p {
  display: none;
}
.navContainer ul li:last-child {
  margin-right: 0;
}
.navContainer ul li img {
  margin-right: 10px;
}
.userAvatar {
  padding: 8px;
  width: 48px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #ffa024;
  border-radius: 100px;
}
.navLinkActive {
  color: #ffa024;
  text-decoration: none;
}
.navLinkInactive {
  color: #696a6b;
  text-decoration: none;
}
.logOutContainer {
  position: absolute;
  top: 70px;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 8px;
  transition: 0.3s ease-in-out all;
}

.OrderWidgetContainer {
  background: #f9f9fb;
  border-style: solid;
  border-color: #e6e7e9;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 0px 0px 24px 24px;
  padding: 12px 20px;
  position: sticky;
  top: 0;
  z-index: 10;
}

.OrderWidgetContainer .filterContainer {
  padding: 8px 16px 8px 8px;
  gap: 12px;
  /* height: 48px; */
  background: #f0f1f2;
  border-radius: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.OrderWidgetContainer .filterContainer .tabNeutral {
  color: "#38383A";
  transition: 0.3s ease-in-out all;
  /* margin: 0 12px; */
}

.OrderWidgetContainer .filterContainer .activeState {
  background: #ffa024;
  border-radius: 16px;
  padding: 4px 12px;
  margin: 0;
  transition: 0.3s ease-in-out all;
  color: #ffffff;
  /* margin: 0 12px; */
}

.orderSearchContainer {
  padding: 12px 16px;
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  width: 100%;
  /* margin-top: 16px; */
}
.orderSearchContainer input {
  width: 100%;
  border: none;
  outline: none;
}

.productsNavContainer {
  padding: 12px 20px;
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  display: flex;
  justify-content: space-between;
}
.usersNavContainer {
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  border-radius: 0px 0px 24px 24px;
}
.activeFilter {
  color: #ffb049;
  border-bottom: 1px solid #ffb049;
  padding-bottom: 4px;
}
.inactiveFilter {
  color: #b1b2b4;
}

@media screen and (min-width: 1200px) {
  .navContainer {
    background: #181819;
    box-shadow: 4px 4px 8px rgba(17, 16, 20, 0.08);
    top: 0;
    bottom: auto;
    padding: 14px 80px;
    position: sticky;
  }
  .navContainer .linksContainer {
    width: 50%;
  }
  .navContainer ul li p {
    display: block;
  }
  .navContainer .logoContainer {
    display: block;
  }
  .navLinkInactive {
    color: #ffffff;
  }
  .productsNavContainer {
    padding: 40px 80px;
    border-radius: 0px 0px 24px 24px;
  }
  .usersNavContainer {
    padding: 40px 80px 20px;
  }
}

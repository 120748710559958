.backComponentContainer {
  height: 35px;
  width: 35px;
}
.backComponentContainer img {
  width: 100%;
}

/* input  */

.inputContainer {
  margin-bottom: 16px;
  height: auto;
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
}
.input {
  border: 1px solid #ebebeb;
  background-color: #ffffff;
  border-radius: 8px;
  height: 48px;
  margin-top: 10px;
  padding: 0 16px;
}
.inputBox {
  border: none;
  /* height: 100%; */
  width: 100%;
  outline: none;
  margin-right: 10px;
}
.input img {
  width: 26px;
  height: 26px;
}
::placeholder {
  font-family: "Nunito", sans-serif;
  font-size: 16px;
  font-weight: normal;
  color: #999ca0;
}

.customSelectContainer {
  padding: 14px 16px;
  /* width: 100%; */
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  position: relative;
}
.customSelectListContainer {
  position: absolute;
  top: 54px;
  background-color: #ffffff;
  border-radius: 0 0 8px 8px;
  left: 0;
  right: 0;
  height: 200px;
  /* margin-bottom: 100px; */
  overflow: scroll;
  z-index: 10;
  box-shadow: 0px 8px 24px -6px rgba(0, 0, 0, 0.16), 0px 0px 1px rgba(0, 0, 0, 0.4);
}
.customSelectListItem {
  padding: 10px 12px;
  margin: 10px;
}

/* button  */
.button {
  border-radius: 8px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  color: #ffffff;
  border: none;
}
.multiButton {
  border-radius: 8px;
  width: 100%;
  padding: 16px;
  color: #ffffff;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Add these styles to your utils.css or a relevant CSS file */
@keyframes glitter {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes colorFlare {
  0% {
    filter: brightness(1);
  }
  100% {
    filter: brightness(1.1);
  }
}

.aiButton.glitter {
  background: linear-gradient(270deg, #ff00cc, #3333ff, #ffcc00, #33ccff);
  background-size: 400% 400%;
  animation: glitter 5s ease infinite;
  position: relative;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}

.aiButton:hover {
  animation: colorFlare 0.5s forwards;
}

.aiButton::before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.2), transparent);
  opacity: 0;
  transition: opacity 0.5s ease;
}

.aiButton:hover::before {
  opacity: 1;
}

/* Loader  */

.loader {
  height: 30px;
  width: 30px;
  border: 1px solid #ffffff;
  border-radius: 50%;
  border-top: #000000;
  animation: circular-loader 2s linear infinite;
}

@keyframes circular-loader {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.checkBoxContainer {
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  width: 16px;
  height: 16px;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.otp-group {
  display: flex;
  width: 100%;
  max-width: 593px;
  /* column-gap: 16px; */
}

.otp-input {
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  height: 48px;
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  font-family: "Nunito";
  margin-right: 10px;
}

.customRiderSelectList {
  border-bottom: 0.5px solid #ebebeb;
  border-top: 0.5px solid #ebebeb;
  padding: 16px 12px;
  /* height: auto; */
}
.customRiderSelectList:hover {
  border: 0.5px solid #ffb049;
  background-color: #f9f9fb;
  margin: 10px;
  transition: 0.3s ease-in-out all;
  padding: 10px;
  border-radius: 8px;
}
.availabilityContainer {
  padding: 4px 8px;
  width: 80px;
  height: 24px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
}

@media screen and (min-width: 1430px) {
  .otp-input {
    margin-right: 40px;
  }
}

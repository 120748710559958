.authContainer {
  display: grid;
  grid-template-columns: 100%;
  background: #ffffff;
  height: 100vh;
}

.authContainer .authLeft {
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.authContainer .authRight {
  display: none;
  background-image: url("../images/adminAuth.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}

.authHeader {
  margin-bottom: 40px;
}
.authRememberContainer {
  margin: 12px 0 48px 0;
}
.forgotPasswordLink {
  color: #ffa024;
}
.authFooterContainer {
  width: 100%;
}

.otpVerifyBtn {
  width: 100%;
  max-width: 593px;
}

@media screen and (min-width: 764px) {
  .authContainer {
    grid-template-columns: 60% 40%;
  }
  .authContainer .authLeft {
    padding: 60px;
  }
  .authContainer .authRight {
    display: block;
  }
}

@media screen and (min-width: 1430px) {
  .authContainer {
    grid-template-columns: 60% 40%;
  }
  .authContainer .authLeft {
    padding: 80px 120px;
  }
  .authContainer .authRight {
    display: block;
  }
}

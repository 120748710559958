.orderManagementContainer {
  padding: 40px 80px 160px 80px;
  background-color: #fafafa;
  /* height: 100vh; */
}

.orderManagementContainer .miniNav {
  margin-bottom: 120px;
}

.ordersSection {
  display: grid;
  grid-template-columns: 70% 30%;
  gap: 16px;
  margin-top: 112px;
}

.activeOrdersContainer {
  isolation: isolate;
  background: inherit;
  height: auto;
  margin-top: 40px;
}
.activeOrdersContainer .activeOrdersHeader {
  padding: 0 0 16px 0;
  background-color: inherit;
}

.activeOrdersCard {
  padding: 16px 16px 24px;
  background: #f9f9fb;
  border: 1px solid #66bb6a;
  border-radius: 16px;
  margin-bottom: 16px;
}
.activeOrdersCard .activeOrdersCardHeader {
  border-bottom: 0.5px solid #d6d6d8;
  padding-bottom: 8px;
  align-items: flex-start;
}
.activeOrdersCard .activeOrdersCardFooter {
  border-top: 0.5px solid #d6d6d8;
  padding-top: 8px;
  margin-top: 16px;
}
.activeOrdersCard .userOrderAvatar {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  border: 1px solid #66bb6a;
  margin-right: 8px;
}
.activeOrderCardStatusBox {
  padding: 2px 8px;
  background: #e1e8ff;
  border-radius: 8px;
  font-size: 10px;
  color: #4c6fff;
  text-transform: uppercase;
}
.activeOrderView {
  padding: 4px 8px;
  border: 1px solid #ffa024;
  border-radius: 8px;
  color: #ffa024;
}

.modalContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(61, 56, 51, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 30;
  height: 100vh;
  z-index: 100;
}

.riderModalContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(61, 56, 51, 0.5);
  display: flex;
  justify-content: center;
  /* align-items: center; */
  z-index: 10000;
}

.mapModalContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(61, 56, 51, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}
.createOrderContainer {
  padding: 60px 80px;
  /* margin-bottom: 100px; */
  /* background-color: #ffffff; */
}
.createOrderModal {
  background: #ffffff;
  border: 1px solid #ffdfb6;
  border-radius: 16px;
  /* overflow: scroll; */
  margin-top: 64px;
  padding: 40px;
  margin-bottom: 100px;
  /* max-height: 500px; */
  /* overflow: scroll; */
}

.mapModal {
  background: #ffffff;
  border: 1px solid #ffdfb6;
  border-radius: 16px;
  margin-top: 64px;
  padding: 40px;
  margin-bottom: 100px;
  width: 80%;
  z-index: 5000;
}

.riderModal {
  background: #ffffff;
  border: 1px solid #ffdfb6;
  border-radius: 16px;
  margin-top: 64px;
  padding: 40px;
  margin-bottom: 100px;
  width: 400px;
  z-index: 1000;
  height: fit-content;
}

.createOrderFooter {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.createOrderModalFooter {
  padding: 24px 0;
  /* border-top: 1px solid #ebebeb; */
}
.createOrderModalFooter img {
  margin-right: 13px;
}
.createOrderModalFooter .footerButtons {
  /* width: 223px; */
}

.createOrderBack {
  border-radius: 8px;
  /* width: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  color: #565758;
  border: 1px solid #565758;
  background-color: #ffffff;
  margin-right: 8px;
}
.createOrderNext {
  border-radius: 8px;
  /* width: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  color: #ffffff;
  border: none;
  background-color: #ffa024;
}
.createOrderCancelBtn {
  border: 1px solid #999ca0;
  background-color: #ffffff !important;
  color: #000000;
}
.mealSetupCounterContainer {
  background: #f9f9fb;
  border-radius: 8px;
  border: 0.5px #e6e7e9 solid;
  height: 48px;
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100px;
  gap: 10px;
  color: #696a6b;
}

.cartList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 10px;
  margin-bottom: 16px;
}
.cartListItem {
  padding: 4px 4px 4px 8px;
  background: #ffa024;
  border-radius: 8px;
  color: #ffffff;
  gap: 10px;
}

.orderDetailsGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
}
.lastCard {
  grid-column: 1 / span 2;
}

.mobileCreateOrderContainer {
  padding: 20px;
}

.mobileCreateOrderModal {
  margin-top: 24px;
}
.mobileCreateOrderFooter {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  padding: 16px 24px;
  border-radius: 16px 16px 0px 0px;
  display: flex;
  justify-content: center;
}
.mobileCreateOrderModalHeader {
  padding-bottom: 24px;
  margin-bottom: 30px;
}
.mobileOrderDetailsButton {
  position: fixed;
  bottom: 0;
  padding: 16px 24px;
  background: #ffffff;
  border-radius: 16px 16px 0px 0px;
  left: 0;
  right: 0;
  z-index: 20;
}

.mobileModalContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(61, 56, 51, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 30;
  height: 100%;
}
.mobileCreateOrderModal {
  padding: 30px 20px;
  background: #ffffff;
  border-radius: 16px 16px 0px 0px;
  /* width: 100%; */
  height: auto;
}

@media screen and (min-width: 1200px) {
  .activeOrdersContainer {
    background: #ffffff;
    box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24),
      0px 3px 8px -1px rgba(50, 50, 71, 0.05);
    border-radius: 16px;
    overflow: scroll;
    margin-top: 0;
    /* height: 500px; */
    max-height: 700px;
  }
  .activeOrdersContainer .activeOrdersHeader {
    padding: 16px 24px 24px 24px;
    border-bottom: 1px solid #edf2f7;
    position: sticky;
    background-color: #ffffff;
    right: 0;
    left: 0;
    top: 0;
  }
  .activeOrderContent {
    padding: 24px;
  }
  .cartList {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 16px;
  }

  .createOrderModalHeader {
    padding-bottom: 24px;
    border-bottom: 1px solid #ebebeb;
    margin-bottom: 40px;
  }

  .modalContainer {
    top: 60px;
  }
  .createOrderFooter {
    padding: 0 80px;
  }
}

@media screen and (max-width: 600px) {
  .mapModal {
    background: #ffffff;
    border: 1px solid #ffdfb6;
    border-radius: 16px;
    margin-top: unset;
    padding: 20px;
    margin-bottom: unset;
    width: 100%;
    height: 96vh;
  }
}

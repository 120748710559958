.statsCardContainer {
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  border-radius: 16px;
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  /* width: 100%; */
}
.statsCardContainer .iconContainer {
  width: 46px;
  height: 46px;
  background: #ff92ae;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.statsCardContainer .rateContainer {
  border-radius: 8px;
  padding: 8px 12px;
  margin-right: 4px;
}
.statsCardSkeleton {
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  border-radius: 16px;
  height: 100px;
}

.tableContainer {
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  border-radius: 16px;
}
.tableContainer .tableHeader {
  padding: 24px;
}
.tableContainer .filterContainer {
  padding: 4px 0;
  /* width: 227px; */
  background: #f0f1f2;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.tableContainer .filterContainer .tabNeutral {
  color: "#38383A";
  transition: 0.3s ease-in-out all;
  margin: 0 12px;
}

.tableContainer .filterContainer .activeState {
  background: #ffa024;
  box-shadow: 0px 6px 6px -6px rgba(0, 0, 0, 0.16), 0px 0px 1px rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  padding: 4px 8px;
  margin: 0;
  transition: 0.3s ease-in-out all;
  color: #ffffff;
  margin: 0 12px;
}
.tableContainer .tableContentContainer {
  width: 100%;
}
.tableContainer .tableContentContainer thead {
  padding: 0 16px;
  background: #f9f9fb;
  box-shadow: inset 0px -1px 0px #edf2f7;
}

.tableContainer .tableContentContainer thead tr td {
  padding: 20px 0;
  font-size: 10px;
  color: #919294;
  border: none;
}

.tableContainer .tableContentContainer thead tr td:first-child {
  padding-left: 16px;
}
.tableContainer .tableContentContainer thead tr td:last-child {
  padding-right: 16px;
}
table {
  border-collapse: collapse;
}
.tableContainer .tableBodyContainer {
  background-color: #ffffff;
}
.tableContainer .tableBodyContainer tr td {
  padding: 16px 0;
}
.tableContainer .tableBodyContainer tr td:first-child {
  padding-left: 16px;
}
.tableContainer .tableBodyContainer tr td:last-child {
  padding-right: 16px;
}
.orderStatusContainer {
  padding: 2px 5px;
  width: 88px;
  /* height: 24px; */
  border-radius: 8px;
}

.paginationContainer {
  padding: 24px 0;
  margin: 0 auto 20px auto;
  width: 100%;
  display: flex;
  justify-content: center;
}

.prevBtn {
  width: 32px;
  height: 32px;
  border: 1px solid #919294;
  border-radius: 8px;
  margin-right: 16px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nextBtn {
  width: 32px;
  height: 32px;
  border: 1px solid #919294;
  border-radius: 8px;
  margin-left: 16px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pageNumberContainer button {
  background-color: #ffffff;
  outline: none;
  border: none;
  margin-right: 8px;
}
.pageNumberContainer button:hover {
  background-color: #ffa024;
  border-radius: 8px;
  padding: 4px 8px;
  transition: 0.3s ease-in-out all;
  color: #ffffff;
}
.pageNumberContainer .active {
  background: rgba(255, 144, 0, 0.12);
  border-radius: 8px;
  padding: 4px 8px;
  color: #ffa024;
}

.cardWithHeaderContainer {
  padding: 16px 22px;
  background: #ffffff;
  border: 1px dashed #ffffff;
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  border-radius: 8px;
}
.cardWithHeaderContainer .cardWithHeaderHeader {
  border-bottom: 0.5px solid #e6e7e9;
  padding-bottom: 16px;
  margin-bottom: 24px;
}

.userOrderAvatar {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  border: 1px solid #66bb6a;
  margin-right: 8px;
}

.moreCardContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 20px 16px;
  gap: 16px;
  min-width: 106.33px;
  height: 111.61px;
  background: #ffffff;
  box-shadow: 0px 0px 1.90341px rgba(12, 26, 75, 0.24), 0px 5.71023px 15.2273px -1.90341px rgba(50, 50, 71, 0.05);
  border-radius: 16px;
}
.moreCardIcon {
  width: 48px;
  height: 48px;
  background: #e6e7e9;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.moreGrid {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  gap: 20px;
  padding: 40px 20px;
  justify-content: center;
}

@media screen and (min-width: 768px) {
  .paginationContainer {
    padding: 24px 0;
    margin: 0 auto;
    max-width: 500px;
  }
}

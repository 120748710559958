.createProductFormContainer {
  background: #ffffff;
  min-width: 100%;
  max-width: 100%;
  overflow: scroll;
  max-height: 500px;
  height: 500px;
}

.createProductHeader {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  padding: 24px;
  /* border-bottom: ; */
}
.createProductFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  margin-bottom: 50px;
}
.createFooterBackBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  width: 100%;
  height: 40px;
  border: 1px solid #999ca0;
  border-radius: 8px;
  background-color: #fff;
  margin-right: 8px;
}
.createFooterNextBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  width: 100%;
  height: 40px;
  background: #ffa024;
  border-radius: 8px;
  border: none;
  color: #fff;
}
.formContent {
  padding: 24px;
}
.createProductsFormGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  /* grid-template-columns: repeat(auto-fit, ); */
  gap: 16px 24px;
  padding-bottom: 16px;
}
.priceInputContainer {
  display: flex;
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  align-items: center !important;
  height: 49px;
  padding: 0 16px;
}

.deleteProductFormContainer {
  background: #ffffff;
  min-width: 100%;
  max-width: 100%;
  align-self: flex-end;
  border-radius: 16px 16px 0 0;
}

.noMargin {
  margin: 0 !important;
  border: none;
  background-color: inherit;
  text-align: center;
}
.tagsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.optionsTag {
  padding: 4px 4px 4px 8px;
  gap: 4px;
  width: 124px;
  height: auto;
  background: #ffa024;
  border-radius: 8px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  transition: 0.3s ease-in-out all;
}

.previewProductContainer {
  padding: 16px;
  border: 1px dashed #e6e7e9;
  border-radius: 8px;
}
.previewProductContainer div:nth-child(1) {
  border-bottom: 1px solid #e6e7e9;
  padding-bottom: 16px;
  margin-bottom: 16px;
}

.previewGrid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  /* gap: ; */
}
.previewGrid ul {
  height: 100%;
}
.previewGrid li {
  margin-bottom: 8px;
}
.previewTagsContainer {
  display: flex;
  flex-wrap: wrap;
  width: 180px;
  justify-content: flex-end;
}
.previewImage {
  width: 200px;
  height: 184px;
  border-radius: 16px;
  object-fit: cover;
}

@media screen and (min-width: 1200px) {
  .createProductFormContainer {
    border: 1px solid #ffdfb6;
    border-radius: 16px;
    min-width: 849px;
    max-width: 849px;
    max-height: 696px;
    /* min-height: 200px; */
  }

  .deleteProductFormContainer {
    min-width: 849px;
    max-width: 849px;
    align-self: center;
    border-radius: 16px;
  }

  .createProductFooter {
    margin-bottom: 0;
    border-top: 1px solid #ebebeb;
    justify-content: flex-end;
  }

  .createProductHeader {
    border-bottom: 1px solid #ebebeb;
    justify-content: space-between;
  }
  .previewGrid ul {
    border-right: 1px solid #f0f1f2;
    padding-right: 16px;
    margin-right: 16px;
  }
  .createFooterBackBtn {
    width: 88px;
  }
  .createFooterNextBtn {
    width: 131px;
  }
}
